import { getAuth, createUserWithEmailAndPassword, updateProfile} from "firebase/auth";
import { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";

export default function Register(){

  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const navigate = useNavigate();
  const [logMessage, SetLogMessage] = useState(' ');


  const handleRegister = (event)=>{
    event.preventDefault();
    createUser();

  }

  const handleName = (event)=>{
    setDisplayName(event.target.value)
  }

  const handleEmail = (event)=>{
    setEmail(event.target.value);
  }

  const handlePass = (event) => {
    setPassword(event.target.value);
  }

  const handleToken = (event) => {
    setToken(event.target.value);
  }

  const createUser = () => {
    if(token === "SC112024"){
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {

        const user = userCredential.user;
        console.log(user);
        console.log(displayName);
        updateProfile(auth.currentUser, {
          displayName: displayName
        }).then(() => {
          navigate("/");
          window.location.reload(false);
        }).catch((error) => {
          console.log(error);
          
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode){
          case 'auth/email-already-in-use':
            SetLogMessage("El mail esta en uso");
            break;
          case 'auth/internal-error':
            SetLogMessage("Error interno");
            break;
          case 'auth/invalid-email':
            SetLogMessage("Usuario incorrecto");
            break;
          case 'auth/invalid-email-verified':
            SetLogMessage("El mail no esta verificado");
            break;
          case 'auth/invalid-password':
            SetLogMessage("Password incorrecto");
            break;
          case 'auth/user-not-found':
            SetLogMessage("ususario no encontrado");
            break;
          case 'auth/weak-password':
            SetLogMessage("Password muy debil");  
            break;
          default:
            SetLogMessage(errorCode);
        }
      });
    }else{
      SetLogMessage("Token incorrecto (Si no lo tiene escribanos a: sn11briandiego@gmail.com");
    }
    
  }
  return(
    <div>
    <main className="login_main">
      <div className="login">
        <div className="login_container">
          <form onSubmit={handleRegister} className="login_container_form">
            <h2> 😀<br/>Rellena los datos para registrarte en nuestra pagina</h2>
            <label>Nombre</label><br/>
            <input name='nombre' type='text' onChange={handleName} value={displayName}/>
            <br/>
            <label>Apellido</label><br/>
            <input type='text'/>
            <br/>
            <label>Correo</label><br/>
            <input type='text' onChange={handleEmail}/>
            <br/>
            <label>Nuevo Password</label><br/>
            <input type='password' onChange={handlePass}/>
            <br/>
            <label>Token</label><br/>
            <input type='text' onChange={handleToken}/>
            <button type='submit'> Registrarse </button>
            <p className="login_message">{logMessage}</p>
          </form>
        </div>
      </div>
    </main>

    </div>
  );
}
