import { useEffect, useState, useRef } from "react";
import {updateCRUD, updateCRUD2, UploadFile} from "../firebase";
import { paises, posiciones } from "../data/data";


export default function UpdatePlayerForm(props){

    const filebox = useRef();
    console.log(props.data);

    const [docIdValue, SetDocIdValue] = useState(props.data.docId)
    const [categoryValue, SetCategoryValue] = useState(props.data.category);
    const [cityValue, SetCityValue] = useState(props.data.category);
    const [clubValue, SetClubValue] = useState(props.data.category);
    const [countryValue, SetCountryValue] = useState(props.data.category);
    const [footValue, SetFootValue] = useState(props.data.category);
    const [nameValue, SetNameValue] = useState(props.data.category);
    const [lastnameValue, SetLastnameValue] = useState(props.data.category);
    const [positionValue, SetPositionValue] = useState(props.data.category);
    const [descriptionValue, SetDescriptionValue] = useState(props.data.description);
    const [linkCV, SetLinkCV] = useState(props.data.linkCV);
    const [linkVideo, SetLinkVideo] = useState(props.data.linkVideo);
    const [linkPartidos, SetLinkPartidos] = useState(props.data.linkPartidos);
    const [imgName, SetImgName] = useState(props.data.imgName);
    const [imgUrl, SetImgUrl] = useState(props.data.imgName);

    const [hasUpdate, SetHasUpdate] = useState(false);
    
    

    useEffect(() => { 
        SetCategoryValue(props.data.category);
        SetCityValue(props.data.city);
        SetClubValue(props.data.club);
        SetCountryValue(props.data.country);
        SetFootValue(props.data.foot);
        SetNameValue(props.data.name);
        SetLastnameValue(props.data.lastname);
        SetPositionValue(props.data.position);
        SetDescriptionValue(props.data.description);
        SetLinkCV(props.data.linkCV);
        SetLinkVideo(props.data.linkVideo);
        SetLinkPartidos(props.data.linkPartidos);
        SetDocIdValue(props.data.docId);
        SetImgName(props.data.imgName);
        SetImgUrl(props.data.imgName)
    },[props.toggle])
    
    const handleUpdate = (event) => {     
        event.preventDefault()
        if(filebox.current.files[0] && hasUpdate){
            UploadFile(filebox.current.files[0]);
        }
        updateCRUD(
            docIdValue,
            categoryValue,
            cityValue,
            clubValue,
            countryValue,
            footValue,
            nameValue,
            lastnameValue,
            positionValue,
            descriptionValue,
            linkCV,
            linkVideo,
            linkPartidos,
            imgName,
        )      
    }

    const handleUpdate2 = (event) => {     
        event.preventDefault();
        
        
        const createCrudObj = {
            colID: "players",
            categoryValue: categoryValue,
            cityValue: cityValue,
            clubValue: clubValue,
            countryValue: countryValue,
            footValue: footValue,
            lastnameValue: lastnameValue,
            nameValue: nameValue,
            positionValue: positionValue,
            descriptionValue: descriptionValue,
            linkCV: linkCV,
            linkVideo: linkVideo,
            linkPartidos: linkPartidos,
            imgName: imgName,
        }
        if(filebox.current.files[0] && hasUpdate){
            const file = filebox.current.files[0];
            const newFileName = Date.now() + file.name.replace(/ /g, '-');
            updateCRUD2(docIdValue, createCrudObj, file, newFileName);
            SetHasUpdate(false); 
        }else{
            updateCRUD2(docIdValue, createCrudObj, null, null);  
        }
            
    }
    
    const handleFlie = (event) => {
        event.preventDefault();
        SetImgName(event.target.files[0].name);
        SetHasUpdate(true);
     }
    return(
        <div>
            <form className="sn_form">
            <div className="sn_form_header">
                <p className="sn_form_header_title">📝  Editar Jugador</p>
                <p className="sn_form_header_close">X</p>
            </div>
            <br/>
            <hr/>
                <div className="sn_form_gird">
                    <div className="sn_form_gird_cm">
                        <input type="file" accept="image/png, image/jpeg" onChange={handleFlie} ref={filebox}/>
                        <label>Nombre</label>
                        <input onChange={e =>  SetNameValue(e.target.value)} value={nameValue}></input>

                        <label>Apellido</label>
                        <input onChange={e =>  SetLastnameValue(e.target.value)} value={lastnameValue}></input>
                        
                        
                        <label>Categoría</label>
                        <input type="number" onChange={e =>  SetCategoryValue(e.target.value)} value={categoryValue}></input>
                        
                        <label>Pais</label>
                        <select name="country" onChange={e =>  SetCountryValue(e.target.value)} value={countryValue}>
                            <option value="" selected></option>
                            {paises.map((pais)=>(
                                <option value={pais}>{pais}</option>
                            ))}
                        </select>
                        
                        <label>Ciudad</label>
                        <input onChange={e =>  SetCityValue(e.target.value)} value={cityValue}></input>
                        
                        <label>Club</label>
                        <input onChange={e =>  SetClubValue(e.target.value)} value={clubValue}></input>
                        
                        <label>Pie</label>
                        <select name="select" onChange={e =>  SetFootValue(e.target.value)} value={footValue}>
                            <option value="" selected></option>
                            <option value="derecho">Derecho</option>
                            <option value="izquierdo">Izquierdo</option>
                            <option value="ambidiestro">Ambidiestro</option>
                        </select>
                                   
                        <label>Posición</label>
                        <select name="position" onChange={e =>  SetPositionValue(e.target.value)} value={positionValue}>
                             <option value="" selected></option>
                            {posiciones.map( (posicinon) => (
                                <option value={posicinon}>{posicinon}</option>
                            ))}
                        </select>

                    </div>
                    <div className="sn_form_gird_cm">
                        
                        <label>Breve Descripción</label>
                        <textarea maxlength="400" type="textarea" onChange={e =>  SetDescriptionValue(e.target.value)} value={descriptionValue}></textarea>
                    
                
                        <label>link CV Deportivo</label>
                        <input onChange={e =>  SetLinkCV(e.target.value)} value={linkCV}></input>
                        
                        <label>link Video</label>
                        <input onChange={e =>  SetLinkVideo(e.target.value)} value={linkVideo}></input>
                        
                        <label>Partidos Completos</label>
                        <input onChange={e =>  SetLinkPartidos(e.target.value)} value={linkPartidos}></input>
                          
                    </div>
                </div>

                
                <input 
                    type="submit" 
                    className="sn_form_btn"
                    value={"Actualizar Jugador"}
                    onClick={handleUpdate2}
                ></input>
                
                <br/>
            </form>

            <hr/>
            
        </div>
    )
}

/*
            <form>
                <label>category</label><br/>
                <input onChange={e =>  SetCategoryValue(e.target.value)} value={categoryValue}></input>
                <br/>
                <label>city</label><br/>
                <input onChange={e =>  SetCityValue(e.target.value)} value={cityValue}></input>
                <br/>
                <label>club</label><br/>
                <input onChange={e =>  SetClubValue(e.target.value)} value={clubValue}></input>
                <br/>
                <label>country</label><br/>
                <input onChange={e =>  SetCountryValue(e.target.value)} value={countryValue}></input>
                <br/>
                <label>foot</label><br/>
                <input onChange={e =>  SetFootValue(e.target.value)} value={footValue}></input>
                <br/>
                <label>lastname</label><br/>
                <input onChange={e =>  SetLastnameValue(e.target.value)} value={lastnameValue}></input>
                <br/>
                <label>name</label><br/>
                <input onChange={e =>  SetNameValue(e.target.value)} value={nameValue}></input>
                <br/>
                <label>position</label><br/>
                <input onChange={e =>  SetPositionValue(e.target.value)} value={positionValue}></input>
                <br/>
                <br/>
                <input 
                    type="submit" 
                    value={"Actualizar Jugador"}
                    onClick={handleUpdate}
                ></input>
                <br/>
            </form>

*/