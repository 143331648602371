import placeholder from "../img/placeholder.png";
import pin from "../img/pin.png";
import {Link} from "react-router-dom";
import { useRef, useState } from "react";
import UpdatePlayerForm from './updatePlayerForm.js';
import {deleteCRUD} from "../firebase";


export default function PlayersCardAdmin(props){

  
  const [toggle, SetToggle] = useState(false)
  const [updateData, SetUpdateData] = useState({
    category: "",
    city: "",
    club: "",
    country: "",
    docId: "",
    foot: "",
    lastname: "",
    name: "",
    position: "",
    description: "",
    linkCV: "",
    linkVideo: "",
    linkPartidos: "",
  })

  const getSelectedPlayer = (docID) =>{
    let SelectedPLayer = props.data.filter( player => player.docId === docID);
  
    SetUpdateData({
      category: SelectedPLayer[0].category || "",
      city: SelectedPLayer[0].city || "",
      club: SelectedPLayer[0].club || "",
      country: SelectedPLayer[0].country || "",
      docId: SelectedPLayer[0].docId || "",
      foot: SelectedPLayer[0].foot || "",
      lastname: SelectedPLayer[0].lastname || "",
      name: SelectedPLayer[0].name || "",
      position: SelectedPLayer[0].position || "",
      description: SelectedPLayer[0].description  || "",
      linkCV: SelectedPLayer[0].linkCV  || "",
      linkVideo: SelectedPLayer[0].linkVideo  || "",
      linkPartidos: SelectedPLayer[0].linkPartidos  || "",
      imgName: SelectedPLayer[0].imgName  || "",
    })
  }

  const handleEdit = (event) => {
    event.preventDefault();
    console.log(event.target.attributes[2].value)
    getSelectedPlayer(event.target.attributes[2].value);
    SetToggle(true);
    setTimeout(() => SetToggle(false), 1000)
   
  }

  const handleDelete = (event) => {
    event.preventDefault();
    console.log(event.target.attributes[2].value)
    deleteCRUD(event.target.attributes[2].value)
  }
 

  return(
    <div>
      <UpdatePlayerForm data={updateData} toggle={toggle}/>
      <table>
        <tr>
          <th>id</th>
          <th>Nombre de Jugador</th>
          <th>Editar</th>
          <th>Eliminar</th>
        </tr>
      
         {props.data.map((player, index) => (
          <tr key={player.docId}>
            <td>{index}</td>
            <td>{player.name || "[name]"} {player.lastname || "[lastname]"}</td>       
            <td><input className="sn_form_btn" type="submit" value="Editar" docId={player.docId} onClick={handleEdit}/></td>
            <td><input className="sn_form_btn" type="submit" value="Eliminar" docId={player.docId} onClick={handleDelete}/></td>
          </tr>
          
        
         ))}
      </table>
    </div>

  )
}
