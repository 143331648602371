export default function Footer(){
  return(
    <footer>
      <div className="footer_container">
        <div>
          <p>2023 Altego. Allright reserved</p>
        </div>
      </div>

    </footer>
  )
}
