import {useParams} from "react-router-dom";
import { useState, useEffect } from 'react';
import {readCRUD} from '../firebase.js';
import cancha from '../img/cancha.png';
import portero from '../img/portero.png';
import libero from '../img/libero.png';
import defensaCentral from '../img/defensa_central.png';
import lateralDerecho from '../img/lateral-derecho.png';
import lateralIzquierdo from '../img/lateral-izquierdo.png';
import carrileroDerecho from '../img/carrilero-derecho.png';
import carrileroIzquierdo from '../img/carrilero-izquierdo.png';
import mediocentro from '../img/mediocentro.png';
import mediocentroDefensivo from '../img/mediocentro-defensivo.png';
import mediocentroOfensivo from '../img/mediocentro_ofensivo.png';
import medioDerecho from '../img/medio_derecho.png';
import medioIzquierdo from '../img/medio_izquierdo.png';
import delanteroCentro from '../img/delantero_centro.png';
import segundoDelantero from '../img/segundo_delantero.png';
import extremoDerecho from '../img/extremo_derecho.png';
import extremoIzquierdo from '../img/extremo_izquierdo.png';
import player from '../img/player.png';

export default function Private(){
  const {id} = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log(data);
    const fetchData = async () => {
      var newData =  await readCRUD('players');
      setData(newData);
      setLoading(false);
    }
    fetchData().catch(console.error);
  }, []);
 
  const getPositionImage = (position) =>{
    switch (position) {
      case "Portero":
        return portero;
        break;
      case "Libero":
        return libero;
        break;
      case "Defensa central":
        return defensaCentral;
        break;
      case "Lateral derecho":
        return lateralDerecho;
        break;
      case "Lateral izquierdo":
        return lateralIzquierdo;
        break;
      case "Carrilero derecho":
        return carrileroDerecho;
        break;
      case "Carrilero izquierdo":
        return carrileroIzquierdo;
        break;
      case "Mediocentro":
        return mediocentro;
        break;
      case "Mediocentro defensivo":
        return mediocentroDefensivo;
        break;
      case "Mediocentro ofensivo":
        return mediocentroOfensivo;
        break;
      case "Medio derecho":
        return medioDerecho;
        break;
      case "Medio izquierdo":
        return medioIzquierdo;
        break;
      case "Delantero centro":
        return delanteroCentro;
        break;
      case "Segundo delantero":
        return segundoDelantero;
        break;
      case "Extremo derecho":
        return extremoDerecho;
        break;
      case "Extremo izquierdo":
        return extremoIzquierdo;
        break;       
      default:
        return cancha
    }
  }
  return (
    <div>
      {!loading &&
        <div className="card">
          <div className="card_card">
            <div className="card_card_data">
              <div className="card_card_data_text">
                <p className="card_card_data_text-player">{data[id].name}  {data[id].lastname}</p>
                <p className="card_card_data_text-club" >{data[id].category}</p>
                <p className="card_card_data_text-description">
                  {data[id].description}
                </p>

                <p className="card_card_data_text-data">Pais: <span>{data[id].country || "no data"}</span></p>
                <p className="card_card_data_text-data">Categoria:  <span>{data[id].category}</span></p>
                <p className="card_card_data_text-data">Posición: <span>{data[id].position}</span></p>
                <p className="card_card_data_text-data">Club:  <span>{ data[id].club || "sin data" }</span></p>
              </div>
              <div className="card_card_data_position">
                <img src={getPositionImage(data[id].position)}/>
              </div>
              <div className="card_card_data_image">
                <img className="card_card_data_text_player" src={data[id].imgName || player}/>
              </div>
            </div>
            <div className="card_card_botonera">
              <a href={data[id].linkCV}><div className="btn btn2">CV Deportivo</div></a>
              <a href={data[id].linkVideo}><div className="btn btn2">Video</div></a>
              <a href={data[id].linkPartidos}><div className="btn btn2">Partidos Completos</div></a>
            </div>
          </div>
        </div>
      }
      {loading &&
        <div className="card">Cargando...</div>
      }

    </div>

  )
}
/*
<div>
  {data[id].name}<br/>
  {data[id].lastname}<br/>
  <br/>
  {data[id].city}<br/>
  <br/>
  <br/>
  {data[id].foot}<br/>
  <br/>
</div>
*/
