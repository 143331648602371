import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import {useNavigate, Link} from "react-router-dom";


// restablecer contraseña

const auth = getAuth();
/*
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User

    const uid = user.uid;

    console.log(user.emailVerified);
    console.log(uid);
    // ...
  } else {
    // User is signed out
    // ...
    console.log("no hay nadie");
  }
});
*/
export default function Login(){

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [logMessage, SetLogMessage] = useState(' ');
  const navigate = useNavigate();

  const handleRegister = (event)=>{
    event.preventDefault();
    console.log(event.target[0].value);
    console.log(event.target[1].value);


    signInWithEmailAndPassword(auth, event.target[0].value, event.target[1].value)
    .then((userCredential) => {
      console.log(userCredential.user);
      const user = userCredential.user;
      navigate("/");
      
    })
    .catch((error) => {
      const errorCode = error.code;

      
      switch (errorCode){
        case 'auth/invalid-email':
          SetLogMessage("Usuario invalido");
          break;
        case 'auth/wrong-password':
          SetLogMessage("Password incorrecto");
          break;
        default:
          SetLogMessage(errorCode);
      }

      
    });
  }





  return(
    <div>
      <main className="login_main">
        <div className="login">
          <div className="login_container">
            <form onSubmit={handleRegister} className="login_container_form">
              <h2>👋 Welcome back!</h2>
              <label>Email</label><br/>
              <input type='text'/>
              <br/>
              <label>Password</label><br/>
              <input type='password'/>
              <button type='submit'> Login </button>
              
              <Link className="login_recovery" to="/recovery"><p>Recuperar contraseña</p></Link>
              <p className="login_message">{logMessage}</p>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}
