import { useEffect, useState, useRef } from "react";
import {createCRUD, createCRUD2, UploadFile} from "../firebase";
import { paises, posiciones } from "../data/data";
import { Logger } from "sass";


export default function CreatePlayerForm(props){

    const filebox = useRef();

    const [categoryValue, SetCategoryValue] = useState("");
    const [cityValue, SetCityValue] = useState("");
    const [clubValue, SetClubValue] = useState("");
    const [countryValue, SetCountryValue] = useState("");
    const [footValue, SetFootValue] = useState("");
    const [nameValue, SetNameValue] = useState("");
    const [lastnameValue, SetLastnameValue] = useState("");
    const [positionValue, SetPositionValue] = useState("");
    const [descriptionValue, SetDescriptionValue] = useState("");
    const [linkCV, SetLinkCV] = useState("");
    const [linkVideo, SetLinkVideo] = useState("");
    const [linkPartidos, SetLinkPartidos] = useState("");
    const [imgName, SetImgName] = useState("");
    
    
   
/*
    const handleSubmit = async (event) => {
        event.preventDefault()  
        if(filebox.current.files[0]){
            const file = filebox.current.files[0];
            const newFileName = Date.now() + file.name.replace(/ /g, '-');          
            const getUrl = await UploadFile(file, newFileName,categoryValue, cityValue, 
                clubValue, 
                countryValue, 
                footValue, 
                lastnameValue, 
                nameValue, 
                positionValue, 
                descriptionValue, 
                linkCV, 
                linkVideo, 
                linkPartidos,); 
        }else{
            createCRUD("players", categoryValue, cityValue, clubValue, countryValue, footValue, lastnameValue, nameValue, positionValue, descriptionValue, linkCV, linkVideo, linkPartidos, imgName);
        }
    }
*/
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        let createCrudObj = {
            colID: "players",
            categoryValue: categoryValue,
            cityValue: cityValue,
            clubValue: clubValue,
            countryValue: countryValue,
            footValue: footValue,
            lastnameValue: lastnameValue,
            nameValue: nameValue,
            positionValue: positionValue,
            descriptionValue: descriptionValue,
            linkCV: linkCV,
            linkVideo: linkVideo,
            linkPartidos: linkPartidos,
            imgName: imgName,
        }
        if(filebox.current.files[0]){
            const file = filebox.current.files[0];
            const newFileName = Date.now() + file.name.replace(/ /g, '-');
            
            createCRUD2(createCrudObj, file, newFileName);         
            
        }else{
            createCRUD2(createCrudObj, null, null); 
        }
    }

    const handleFlie = (event) => {
        event.preventDefault();
        SetImgName(event.target.files[0].name)
        //UploadFile(event.target.files[0]);
    }

    return (
        <div>    
            <form className="sn_form">
                
                <div className="sn_form_header">
                    <p className="sn_form_header_title">📥 Crear Juagador</p>
                    <p className="sn_form_header_close">X</p>
                </div>
                <br/>
                <hr/>
                    <div className="sn_form_gird">
                        <div className="sn_form_gird_cm">
                            <input type="file" accept="image/png, image/jpeg" onChange={handleFlie} ref={filebox}/>
                            <label>Nombre</label>
                            <input onChange={e =>  SetNameValue(e.target.value)} value={nameValue}></input>

                            <label>Apellido</label>
                            <input onChange={e =>  SetLastnameValue(e.target.value)} value={lastnameValue}></input>
                            
                            
                            <label>Categoría</label>
                            <input type="number" onChange={e =>  SetCategoryValue(e.target.value)} value={categoryValue}></input>
                            
                            <label>Pais</label>
                            <select name="country" onChange={e =>  SetCountryValue(e.target.value)} value={countryValue}>
                                <option value="" selected></option>
                                {paises.map((pais)=>(
                                    <option value={pais}>{pais}</option>
                                ))}
                            </select>
                            
                            <label>Ciudad</label>
                            <input onChange={e =>  SetCityValue(e.target.value)} value={cityValue}></input>
                            
                            <label>Club</label>
                            <input onChange={e =>  SetClubValue(e.target.value)} value={clubValue}></input>
                            
                            <label>Pie</label>
                            <select name="select" onChange={e =>  SetFootValue(e.target.value)} value={footValue}>
                                <option value="" selected></option>
                                <option value="derecho">Derecho</option>
                                <option value="izquierdo">Izquierdo</option>
                                <option value="ambidiestro">Ambidiestro</option>
                            </select>
                                    
                            <label>Posición</label>
                            <select name="position" onChange={e =>  SetPositionValue(e.target.value)} value={positionValue}>
                                <option value="" selected></option>
                                {posiciones.map( (posicinon) => (
                                    <option value={posicinon} selected>{posicinon}</option>
                                ))}
                            </select>
                        </div>
                        <div className="sn_form_gird_cm">
                            
                            <label>Breve Descripción</label>
                            <textarea maxlength="400" type="textarea" onChange={e =>  SetDescriptionValue(e.target.value)} value={descriptionValue}></textarea>
                        
                    
                            <label>link CV Deportivo</label>
                            <input onChange={e =>  SetLinkCV(e.target.value)} value={linkCV}></input>
                            
                            <label>link Video</label>
                            <input onChange={e =>  SetLinkVideo(e.target.value)} value={linkVideo}></input>
                            
                            <label>Partidos Completos</label>
                            <input onChange={e =>  SetLinkPartidos(e.target.value)} value={linkPartidos}></input>
                            
                        </div>
                    </div>

                    
                    <input 
                        type="submit" 
                        className="sn_form_btn"
                        value={"Crear Jugador"}
                        onClick={handleSubmit2}
                    ></input>
                    
                    <br/>
            </form>   
        </div>
    )
}