import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDocs, addDoc, updateDoc, deleteDoc} from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";




const firebaseConfig = {
  apiKey: "AIzaSyCZVPfGMyufpLRk0ydoXQRSfV7FHuCcoPs",
  authDomain: "scoutingnetwork-3b069.firebaseapp.com",
  projectId: "scoutingnetwork-3b069",
  storageBucket: "scoutingnetwork-3b069.appspot.com",
  messagingSenderId: "446252563481",
  appId: "1:446252563481:web:99d07b2d46fa99a86a7e88",
  storageBucket: "gs://scoutingnetwork-3b069.appspot.com"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export const auth = getAuth(app);


export async function UploadFile(file, fileName, categoryValue, cityValue, clubValue, countryValue, footValue, lastnameValue, nameValue, positionValue, descriptionValue, linkCV, linkVideo, linkPartidos,){
  const storageRef = ref(storage, fileName);
  let downloadURL = "pepe";
  uploadBytes(storageRef, file)
  .then((snapshot) => {
      console.log('Uploaded a blob or file!')
      console.log(snapshot)
  })
  .then(async (data) => {
    downloadURL = await getDownloadURL(ref(storage, fileName))
    createCRUD2("players", 
    categoryValue, 
    cityValue, 
    clubValue, 
    countryValue, 
    footValue, 
    lastnameValue, 
    nameValue, 
    positionValue, 
    descriptionValue, 
    linkCV, 
    linkVideo, 
    linkPartidos, 
    downloadURL
    )
    
  })
  return await downloadURL
}

export async function UploadFile2(file, fileName, categoryValue, cityValue, clubValue, countryValue, footValue, lastnameValue, nameValue, positionValue, descriptionValue, linkCV, linkVideo, linkPartidos,){
  const storageRef = ref(storage, fileName);
  let downloadURL = "pepe";
  uploadBytes(storageRef, file)
  .then((snapshot) => {
      console.log('Uploaded a blob or file!')
      console.log(snapshot)
  })
  .then(async (data) => {
    downloadURL = await getDownloadURL(ref(storage, fileName))
    createCRUD2("players", 
    categoryValue, 
    cityValue, 
    clubValue, 
    countryValue, 
    footValue, 
    lastnameValue, 
    nameValue, 
    positionValue, 
    descriptionValue, 
    linkCV, 
    linkVideo, 
    linkPartidos, 
    downloadURL
    )
    
  })
  return await downloadURL
}

export async function DownloadFile(filename){
  await getDownloadURL(ref(storage, filename)).then((url) => {
    return url
  });
}

/*
export async function createCRUD(colID, category, city, club, country, foot, lastname, playername, position, description, linkCV, linkVideo, linkPartidos, downloadURL, file, fileName,){
  const coleccion = collection(db, colID);
  const storageRef = ref(storage, fileName);
  //pasar el filename
  //pasar el file
  //upload
  //download
  //create
  let player = {
    category: category,
    city: city,
    club: club,
    country: country,
    foot: foot,
    lastname: lastname,
    name: playername,
    position: position,
    description: description,
    linkCV: linkCV,
    linkVideo: linkVideo,
    linkPartidos: linkPartidos,
    imgName: downloadURL
  }
  console.log(player)
  const adder = await addDoc(coleccion, player);
  console.log("Un nuevo jugador ha sido agregado");
  //window.location.reload();
}
*/
export async function createCRUD2(createCrudObj, file, newFileName){
  debugger;
  const coleccion = collection(db, createCrudObj.colID);
  const storageRef = ref(storage, newFileName);
  let downloadURL = null;

  var player = {
    category: createCrudObj.categoryValue,
    city: createCrudObj.cityValue,
    club: createCrudObj.clubValue,
    country: createCrudObj.countryValue,
    foot: createCrudObj.footValue,
    lastname: createCrudObj.lastnameValue,
    name: createCrudObj.nameValue,
    position: createCrudObj.positionValue,
    description: createCrudObj.descriptionValue, 
    linkCV: createCrudObj.linkCV,
    linkVideo: createCrudObj.linkVideo,
    linkPartidos: createCrudObj.linkPartidos,
    imgName: downloadURL
  }

  if(file){
    uploadBytes(storageRef, file)
    .then((snapshot) => {
        console.log('Uploaded a blob or file!')
        console.log(snapshot)
    })
    .then(async () => {
      downloadURL = await getDownloadURL(ref(storage, newFileName))
      const playerWithUrl = Object.assign(player, {imgName: downloadURL});
      const adder = await addDoc(coleccion, playerWithUrl);
      window.location.reload();
    })
  }else{
    const playerWithUrl = Object.assign(player, {imgName: downloadURL});
    const adder = await addDoc(coleccion, playerWithUrl);
    window.location.reload();
  }

  
}

export async function readCRUD(colID){
  const coleccion = collection(db, colID);
  const documentos = await getDocs(coleccion);
 
  const documentosList = documentos.docs.map((doc, index) =>  {
    return Object.assign({},doc.data(), {docId: doc.id})
  } );

  //console.log("la base de datos ha sido leida");
  return documentosList;
}

export async function updateCRUD( docID, category, city, club, country, foot, lastname, playername, position, description, linkCV, linkVideo, linkPartidos, imgName ){
  const documento = doc(db, 'players', docID);
  const editer = await updateDoc(documento, {
    category: category,
    city: city,
    club: club,
    country: country,
    foot: foot,
    lastname: lastname,
    name: playername,
    docId: docID,
    position: position,
    description: description,
    linkCV: linkCV,
    linkVideo: linkVideo,
    linkPartidos: linkPartidos,
    imgName, imgName,
  });
  console.log(docID, "ha sido editado");
  window.location.reload();
}

export async function updateCRUD2(docIdValue, createCrudObj, file, newFileName){
  const documento = doc(db, 'players', docIdValue);
  //const coleccion = collection(db, createCrudObj.colID);
  const storageRef = ref(storage, newFileName);
  let downloadURL = createCrudObj.imgName;
  var player = {
    category: createCrudObj.categoryValue,
    city: createCrudObj.cityValue,
    club: createCrudObj.clubValue,
    country: createCrudObj.countryValue,
    foot: createCrudObj.footValue,
    lastname: createCrudObj.lastnameValue,
    name: createCrudObj.nameValue,
    position: createCrudObj.positionValue,
    description: createCrudObj.descriptionValue, 
    linkCV: createCrudObj.linkCV,
    linkVideo: createCrudObj.linkVideo,
    linkPartidos: createCrudObj.linkPartidos,
    imgName: createCrudObj.imgName,
  }
  console.log(player)
  if(file){
    uploadBytes(storageRef, file)
    .then((snapshot) => {
        console.log('Uploaded a blob or file!')
        console.log(snapshot)
    })
    .then(async () => {
      
      downloadURL = await getDownloadURL(ref(storage, newFileName))
      console.log("****",downloadURL)
      const playerWithUrl = Object.assign(player, {imgName: downloadURL});
      const adder = await updateDoc(documento, playerWithUrl);
      console.log(docIdValue, "ha sido editado");
      window.location.reload();
    })
  }else{
    const playerWithUrl = Object.assign(player, {imgName: downloadURL});
    const adder = await updateDoc(documento, playerWithUrl);
    console.log(docIdValue, "ha sido editado");
    window.location.reload();
  }
  
  
}

export async function deleteCRUD(docID){
  
  const documento = doc(db, 'players', docID);
  const editer = await deleteDoc(documento);
  console.log(docID, "ha sido eliminado");
  window.location.reload();
}
