import { getAuth, onAuthStateChanged } from "firebase/auth";
import {readCRUD} from '../firebase.js';
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import CreatePlayerForm from '../components/createPlayerForm';
import PlayersCardAdmin from '../components/playersCardAdmin.js';
import {admin} from '../config.js';

//AUTH
const auth = getAuth();
var online = false;


//// TODO: hacer una funcion modular
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    if(user.uid === admin ){
      online = true;
      //console.log("Welcome," + uid);
    }
    
  } else {
    //console.log("Welcome, unknow user");
  }
});

export default function Cpanel(){

  const navigate = useNavigate();

  const [ctoggle, SetCtoggle] = useState(false);
  const [etoggle, SetEtoggle] = useState(false);
  const [data, setData] = useState([]);
  const [updateData, SetUpdateData] = useState({
    category: '',
    city: '',
    club: '',
    country: '',
    foot: '',
    lastname: '',
    name: '',
    position: '',
    description: "",
    linkCV: "",
    linkVideo: "",
    linkPartidos: "",
    updating: false
  })


  useEffect(() => { 
    const fetchData = async () => { 
      var temp = await readCRUD('players');
      //console.warn(temp[0].name)
      setData(temp)
    }
    fetchData()
    .then()
    .catch((err) => { console.error(err) });
  }, [])


  if(online){
    return(
      <>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="cpanel_titles">
          <h2>Bienvenido al C-Panel</h2>
          <p>Bienvenido al C-Panel, aqui podrá administrar los jugadores </p><br/>
        </div>
  
        <br/>
        <div className="cpanel_botonera">
          <div className="btn btn2" onClick={() => {SetCtoggle(!ctoggle); SetEtoggle(false)} }>Crear Jugador</div>
          <div className="btn btn2" onClick={() => {SetEtoggle(!etoggle); SetCtoggle(false)} }>Editar Jugador</div>
        </div>
        <hr/>
        {ctoggle &&
          <CreatePlayerForm data={updateData} />
        }
        
        
  
        {etoggle &&
          <PlayersCardAdmin data={data}/>
        }
        
      </>
    )
  }else{
    return (
      navigate("/login")
    )
  }


}
