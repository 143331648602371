import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState, useRef } from "react";
import {useNavigate} from "react-router-dom";


const auth = getAuth();


export default function Recovery() {

    const [mailSubmited, SetMailSubmited] = useState("");
    const msj = useRef('mensaje');
    const navigate = useNavigate();

    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.log("handle submit");
        if(mailSubmited !== null){
            sendPasswordResetEmail(auth, mailSubmited)
            .then(() => {
                console.log("Password reset email sent!")
                msj.current.innerText = "Emain enviado";
                setTimeout(()=>{navigate("/");}, 2000)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
            });
        }else{
            console.log("falta el mail")
        }     
    }

    return (
      <div>
        <main>
            <div className="recovery">
                <div  className="recovery_container">
                    <form className="recovery_container_form">
                        <h2>Recuperar contraseña</h2>
                        <label>Email</label><br/>
                        <input type="text" onChange={e =>  SetMailSubmited(e.target.value)}></input><br/>
                        <button type="submit" onClick={handleSubmit}>Enviar Mail</button>
                        <p ref={msj}></p>
                    </form>
                    
                </div>
                
            </div>   
        </main>

      </div>
  
    );
  }