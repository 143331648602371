import React from 'react';
import ok from '../img/ok.png';

const downbanner = () => {
  return (
    <div className='consultoria'>
        <div className='consultoria_container'>  
            <ul>
                <h3>CONSULTORÍA</h3>
                <div className='consultoria_container_flex'>
                    <div>
                        <li><img src={ok}/>Análisis colectivo e individual de rivales y equipo propio.</li>
                        <li><img src={ok}/>Conformación del plantel</li>
                        <li><img src={ok}/>Creación de rankings y/o métricas especializadas</li>
                    </div>
                    <div>                    
                        <li><img src={ok}/>Visualizaciones, reportes y/o herramientas interactivas personalizadas</li>
                        <li><img src={ok}/>Capacitaciones</li>
                    </div>
                </div>
                
            </ul>
        </div>
    </div>
  )
}

export default downbanner